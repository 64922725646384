<template>
  <div class="lottie-container">
    <lottie :options="lottieOptions" @animCreated="handleAnimation" />
  </div>
</template>

<script>
  import * as success from "@/assets/lottie/mo_consulting_01.json";
  export default {
    name: "lottie-consulting01",
    data() {
      return {
        lottieOptions: {animationData: success.default},
        animationSpeed: 1
      };
    },
    methods: {
      handleAnimation: function(anim) {
        this.anim = anim;
        this.anim.loop = true;
        this.anim.setSpeed(1);
      }
    }
  };
</script>

<style scoped lang="scss"></style>
