<template>
  <div>
    <div class="key-visual">
      <div class="mask">
        <div class="contents">
          <div class="txt">
            이모션글로벌은<br />
            프로젝트에<br />
            맞춤 컨설팅을 제공합니다.
          </div>
        </div>
        <div class="bg"></div>
      </div>
    </div>
    <section class="section section-1">
      <h3 class="section-title">
        이모션글로벌에서는<br />
        다음과 같은 컨설팅을 수행합니다.
      </h3>
      <div class="cont-inner" :class="{open: isOpen}">
        <ul class="cont-list">
          <li v-for="(item, index) in contList" :key="index">
            <h4 class="tit">
              <strong>{{ item.title_1 }}</strong
              >{{ item.title_2 }}
            </h4>
            <p class="txt">
              {{ item.txt }}
            </p>
          </li>
        </ul>
      </div>
      <button class="btn-open" type="button" @click="toggle">
        {{ isOpen ? "접기" : "더 보기" }}
      </button>
    </section>
    <div class="bg-img">
      <img src="@/assets/images/consulting/list_bg.png" alt="배경이미지" />
    </div>
    <section class="section section-2">
      <h3 class="section-title">
        <span class="sub">
          이모션 컨설팅 방법론 “퍼즐”
        </span>
        프로젝트 목표에 맞는<br />
        솔루션을 제공하는 이모션글로벌만의<br />
        유연한 전략 컨설팅 방법론을<br />
        우리는 <span class="puzzle">퍼즐</span>
        <img class="puzzle-img" src="@/assets/images/consulting/puzzle.svg" alt="퍼즐" />
        이라고 부릅니다.
      </h3>
      <div class="lottie-cont lottie-02">
        <lottie-consulting01 />
      </div>
    </section>
    <section class="section section-3">
      <h3 class="section-title">
        <span class="sub">
          컨실팅 프로세스 “퍼즐보드”
        </span>
        프로젝트에 적합한<br />
        최적의 컨설팅 프로세스를<br />
        우리는 <span class="puzzle">퍼즐보드</span>
        <img
          class="puzzle-img"
          src="@/assets/images/consulting/puzzle_02.svg"
          alt="퍼즐보드"
        /><br />
        라고 부릅니다.
      </h3>
      <ul class="board-list">
        <li>
          <span class="board-img">
            <img src="@/assets/images/consulting/board_00.png" alt="일반적으로 사용하는 프로세스" />
          </span>
          <h4 class="tit">
            일반적으로 사용하는 프로세스
          </h4>
          <p class="txt">
            이해, 발견, 분석, 해결, 구현 과정으로 진행되며<br />결과를 안정적으로 얻을수 있어 가장
            보편적으로<br />
            많이 활용되는 프로세스입니다.
            <span class="tag">#안정적 #일반적 #관용적</span>
          </p>
        </li>
        <li>
          <span class="board-img">
            <img
              src="@/assets/images/consulting/board_01.png"
              alt="사전 학습 난이도가 높은 프로세스"
            />
          </span>
          <h4 class="tit">
            사전 학습 난이도가 높은 프로세스
          </h4>
          <p class="txt">
            프로젝트의 이해를 위한 사전 학습의 난이도가<br />
            높거나 또는 분량이 많은 프로젝트에 적합한<br />
            프로세스입니다.
            <span class="tag">#전문적 #효과적 #안정적</span>
          </p>
        </li>
        <li>
          <span class="board-img">
            <img
              src="@/assets/images/consulting/board_02.png"
              alt="짧은 시간에 빠른 결과를 내는 프로세스"
            />
          </span>
          <h4 class="tit">
            짧은 시간에 빠른 결과를 내는 프로세스
          </h4>
          <p class="txt">
            짧은 일정에 빠르게 컨설팅 결과 확인이 필요한<br />
            프로젝트, 또는 구축 관련 별도 프로젝트를 진행<br />
            예정인 경우 적합한 프로세스입니다.
            <span class="tag">#짧은일정 #효율적 #경제적</span>
          </p>
        </li>
        <li>
          <span class="board-img">
            <img
              src="@/assets/images/consulting/board_03.png"
              alt="짧은 시간에 빠른 결과를 내는 프로세스"
            />
          </span>
          <h4 class="tit">
            3가지 프로세스 외에도 프로젝트 목적에 맞게<br />새로운 프로세스를 구성하여 진행할 수
            있습니다.
          </h4>
        </li>
      </ul>
    </section>
    <section class="section section-4">
      <h3 class="section-title">
        <span class="sub">
          연구방법론 “퍼즐피스”
        </span>
        이모션글로벌이 수행 가능한<br />
        여러 연구방법론을 우리는<br />
        우리는 <span class="puzzle">퍼즐피스</span>
        <img class="puzzle-img" src="@/assets/images/consulting/puzzle_03.svg" alt="퍼즐보드" />
        라고 부릅니다.
      </h3>
      <div class="piece-wrap" :class="{open: isOpen2}">
        <ul class="piece-list">
          <li v-for="(item, index) in pieceList" :key="index">
            <div class="tit-wrap">
              <h4 class="tit">
                {{ item.title }}
              </h4>
              <p class="txt">
                {{ item.txt }}
              </p>
            </div>
            <img class="piece-img" :src="item.img" :alt="item.title" />
          </li>
        </ul>
        <div class="btn-wrap">
          <button class="btn-detail" type="button" @click="openModal">
            퍼즐피스 상세 설명 보기
          </button>
        </div>
      </div>
      <button class="btn-open" type="button" @click="toggle2">
        {{ isOpen2 ? "접기" : "더 보기" }}
      </button>
    </section>
    <section class="section section-5">
      <h3 class="section-title">
        프로젝트 전반에 걸쳐<br />AI기반의 데이터 분석을 활용하여<br />업무 생산성을 높입니다.
      </h3>
      <p class="section-txt">
        컨설팅 과정에서 AI를 적극 활용하여 자원을<br />
        효율적으로 관리하고, 시간과 비용을 절감하여<br />
        전반적인 생산성을 높이고 보다 깊이 있는 분석과<br />
        가치 있는 결과를 제공합니다.
      </p>
      <div class="lottie-cont lottie-02">
        <lottie-consulting02 />
      </div>
    </section>
    <section class="section section-6">
      <h3 class="section-title">
        프로젝트의 전 과정을 체계적이고<br />
        효율적으로 진행합니다.
      </h3>
      <p class="section-txt">
        각 단계를 신중하게 설계하여 클라이언트가<br />
        기대 이상의 가치를 실현할 수 있도록 집중합니다.
      </p>
      <ul class="process-list">
        <li>
          <h4 class="tit">
            <span class="num">
              01
            </span>
            사전준비
          </h4>
          <div class="img">
            <img src="@/assets/images/consulting/process_1.png" alt="사전준비" />
          </div>
          <div class="desc">
            <p class="txt">클라이언트의 목표를 바탕으로 프로젝트 방향성을 설정하고 구체화합니다.</p>
            <p class="txt">1 WEEK</p>
          </div>
        </li>
        <li>
          <h4 class="tit">
            <span class="num">
              02
            </span>
            협의
          </h4>
          <div class="img">
            <img src="@/assets/images/consulting/process_2.png" alt="협의" />
          </div>
          <div class="desc">
            <p class="txt">
              프로젝트 목표에 맞는 방법론을 제안하며<br />
              협의 후 최종 의사 결정을 내립니다.
            </p>
            <p class="txt">1 WEEK</p>
          </div>
        </li>
        <li>
          <h4 class="tit">
            <span class="num">
              03
            </span>
            컨설팅 수행
          </h4>
          <div class="img">
            <img src="@/assets/images/consulting/process_3.png" alt="컨설팅 수행" />
          </div>
          <div class="desc">
            <p class="txt">
              컨설팅을 통해 제안된 솔루션을 구체화하고<br />
              시각적 프로토타입으로 구현합니다.
            </p>
            <p class="txt">6 WEEK ~</p>
          </div>
        </li>
        <li>
          <h4 class="tit">
            <span class="num">
              04
            </span>
            전달
          </h4>
          <div class="img">
            <img src="@/assets/images/consulting/process_4.png" alt="전달" />
          </div>
          <div class="desc">
            <p class="txt">
              산출물을 전달하며 프로젝트를 마무리하고,<br />
              필요 시 구축 및 연계 작업을 논의합니다.
            </p>
            <p class="txt">6 WEEK ~</p>
            <p class="txt">* 기간은 예시이며, 프로젝트에 따라 변동이 있을 수 있습니다.</p>
          </div>
        </li>
      </ul>
    </section>
    <section class="section swiper">
      <h3 class="section-title">
        검증된 전문성과 노하우를 바탕으로, <br />성공적으로 완수한 프로젝트들을 소개합니다.
      </h3>
      <div class="swiper-section" ref="swiperSection">
        <swiper class="swiper-container" :options="swiperOptions">
          <swiper-slide
            v-for="(item, index) in swiperData"
            :key="index"
            :style="`background: url(${item.bgImg}) center / cover no-repeat`"
          >
            <div class="inner">
              <div
                class="logo swiper-text"
                :style="`background: url(${item.logoImg}) 0 0 / cover no-repeat`"
              >
                {{ item.title }}
              </div>
              <h3 class="title swiper-text">
                <strong v-html="item.title_1"></strong>
                {{ item.title_2 }}
              </h3>
              <p class="sm-tit swiper-text">
                {{ item.sm_tit }}
              </p>
              <p class="sm-txt swiper-text">
                {{ item.sm_txt }}
              </p>
            </div>
          </swiper-slide>
        </swiper>
        <div class="swiper-button-next">
          <img src="@/assets/icons/ic_nav.svg" alt="swiper_arrow" />
        </div>
        <div class="swiper-button-prev">
          <img src="@/assets/icons/ic_nav.svg" alt="swiper_arrow" />
        </div>
      </div>
    </section>
    <modal-component v-if="isModalOpen" @close="closeModal">
      <template v-slot:modal-header>
        <h3 class="modal-title">26개의 퍼즐 피스 <span>연구방법론</span></h3>
      </template>
      <template v-slot:modal-contents>
        <div class="modal-contents">
          <div class="img">
            <img src="@/assets/images/consulting/modal-img.jpg" alt="" />
          </div>
        </div>
      </template>
    </modal-component>
  </div>
</template>
<script>
  import {Swiper, SwiperSlide} from "vue-awesome-swiper";
  import modalComponent from "@/components/global/modal-component.vue";
  import "swiper/css/swiper.css";
  import {TweenMax, Power0} from "gsap/all";
  import {Power2} from "gsap";
  import {commonMethods} from "@/utils/common-methods";
  import LottieConsulting01 from "@/components/lottie/consulting01.vue";
  import LottieConsulting02 from "@/components/lottie/consulting02.vue";

  export default {
    name: "Consulting",
    metaInfo() {
      return {
        title: "Consulting",
        titleTemplate: "%s | e · motion"
      };
    },
    components: {LottieConsulting01, LottieConsulting02, Swiper, SwiperSlide, modalComponent},
    mixins: [commonMethods],
    data() {
      return {
        isOpen: false,
        scrollPosition: 0,
        isOpen2: false,
        scrollPosition2: 0,
        contList: [
          {
            title_1: "UX Consulting",
            title_2: "사용자 경험 컨설팅",
            txt:
              "사용자가 서비스를 더 쉽고 편리하며 만족스러운\n" +
              "경험을 제공할 수 있도록 설계와 개선 방향을\n" +
              "제안합니다."
          },
          {
            title_1: "BX Consulting",
            title_2: "사용자 경험 컨설팅",
            txt:
              "브랜드가 고객과 만나는 모든 접점에서 일관되고\n" +
              "긍정적인 이미지를 전달할 수 있도록 돕습니다."
          },
          {
            title_1: "CX Consulting",
            title_2: "고객 경험 컨설팅",
            txt:
              "고객이 서비스를 처음 접할 때부터의 모든 단계에서 만족스럽고 긍정적인 경험을 하도록 개선합니다."
          },
          {
            title_1: "Visual Prototyping",
            title_2: "시각화 (프로토타이핑)",
            txt:
              "컨설팅 결과물을 시각화(디자인 + 프로토타이핑) 하여 눈에 보이는 결과물이 나오는 컨설팅을 진행합니다."
          },
          {
            title_1: "UX Writing",
            title_2: "사용자 경험에 맞춘 글쓰기",
            txt:
              "사용자가 디지털 제품이나 서비스를 쉽게 이해하고 원활하게 사용할 수 있도록 글의 스타일과 체계를 수립합니다."
          },
          {
            title_1: "BX Writing",
            title_2: "브랜드 경험에 맞춘 글쓰기",
            txt:
              "브랜드 인상과 이미지를 결정짓는 보이스 톤 연구를 기반으로, 브랜드에 적합한 글의 스타일과 원칙을 수립합니다."
          },
          {
            title_1: "Data Analytics",
            title_2: "데이터 분석",
            txt:
              "데이터를 수집, 처리, 분석하여 의미 있는 인사이트를 도출하고 의사결정을 돕는 근거 있는 컨설팅을 진행합니다."
          },
          {
            title_1: "Design System",
            title_2: "디자인 시스템",
            txt:
              "디자인 방향성을 수립하고, 체계화된 디자인 시스템과 GUI를 통해 완성도 높은 결과물을 만듭니다."
          },
          {
            title_1: "UX Research",
            title_2: "사용자 경험 분석",
            txt:
              "다양한 방법론을 활용하여 데이터를 수집, 분석하고 이를 바탕으로 사용자 경험을 연구합니다."
          },
          {
            title_1: "Accessibility Consulting",
            title_2: "접근성 컨설팅",
            txt:
              "정보취약계층의 접근성과 사용성을 평가하고 개선 방안을 제공하는 전문 컨설팅을 진행합니다."
          },
          {
            title_1: "UX/UI Design",
            title_2: "사용자 경험/사용자 인터페이스 디자인",
            txt:
              "근거 있는 컨설팅이 진행된 후, UX/UI 설계와 디자인을 통해 최종 결과를 구체화합니다."
          },
          {
            title_1: "UX Education",
            title_2: "사용자 경험 관련 교육",
            txt:
              "다양한 경험이 있는 전문가가 UX전문 역량을 기반으로 필요한 지식과 노하우를 공유하는 세미나를 진행합니다."
          },
          {
            title_1: "Operations",
            title_2: "운영",
            txt:
              "서비스 핵심 지표에 대한 지속적인 점검을 통해 반복적인 개선을 진행하여, 서비스의 운영과 성장을 지원합니다."
          }
        ],
        pieceList: [
          {
            title: "Co-creation Workshop",
            txt: "코크리에이션 워크샵",
            img: require("@/assets/images/consulting/piece-01.svg")
          },
          {
            title: "Desk Research",
            txt: "데스크 리서치",
            img: require("@/assets/images/consulting/piece-02.svg")
          },
          {
            title: "Focus Group Interview",
            txt: "표적 집단 면접법",
            img: require("@/assets/images/consulting/piece-03.svg")
          },
          {
            title: "In-depth Interview",
            txt: "심층 인터뷰",
            img: require("@/assets/images/consulting/piece-04.svg")
          },
          {
            title: "Contextual Inquiry",
            txt: "사용자 리서치 정황조사",
            img: require("@/assets/images/consulting/piece-05.svg")
          },
          {
            title: "Service Safari",
            txt: "서비스 사파리",
            img: require("@/assets/images/consulting/piece-06.svg")
          },
          {
            title: "Expert Interview",
            txt: "전문가 인터뷰",
            img: require("@/assets/images/consulting/piece-07.svg")
          },
          {
            title: "Usability Test",
            txt: "사용성 테스트",
            img: require("@/assets/images/consulting/piece-08.svg")
          },
          {
            title: "Persona",
            txt: "퍼소나",
            img: require("@/assets/images/consulting/piece-09.svg")
          },
          {
            title: "Journey map",
            txt: "고객 여정 지도",
            img: require("@/assets/images/consulting/piece-10.svg")
          },
          {
            title: "Log Analytics",
            txt: "로그 분석",
            img: require("@/assets/images/consulting/piece-11.svg")
          },
          {
            title: "Voice of Customer",
            txt: "고객의 소리",
            img: require("@/assets/images/consulting/piece-12.svg")
          },
          {
            title: "Eyetracking",
            txt: "시선추적",
            img: require("@/assets/images/consulting/piece-13.svg")
          },
          {
            title: "Survey",
            txt: "설문조사",
            img: require("@/assets/images/consulting/piece-14.svg")
          },
          {
            title: "Voting",
            txt: "투표하기",
            img: require("@/assets/images/consulting/piece-15.svg")
          },
          {
            title: "Affinity Diagram",
            txt: "친화도법",
            img: require("@/assets/images/consulting/piece-16.svg")
          },
          {
            title: "Card Sorting",
            txt: "서비스 정보 그룹화",
            img: require("@/assets/images/consulting/piece-17.svg")
          },
          {
            title: "Mind Mapping",
            txt: "정보 시각화 지도",
            img: require("@/assets/images/consulting/piece-18.svg")
          },
          {
            title: "Heuristics",
            txt: "전문가 UX 평가",
            img: require("@/assets/images/consulting/piece-19.svg")
          },
          {
            title: "Positioning Map",
            txt: "지각도",
            img: require("@/assets/images/consulting/piece-20.svg")
          },
          {
            title: "Mental Model",
            txt: "멘탈모델",
            img: require("@/assets/images/consulting/piece-21.svg")
          },
          {
            title: "Expert Solutions",
            txt: "전문가 솔루션",
            img: require("@/assets/images/consulting/piece-22.svg")
          },
          {
            title: "Service Prototyping",
            txt: "서비스 프로토타이핑",
            img: require("@/assets/images/consulting/piece-23.svg")
          },
          {
            title: "UX Flow",
            txt: "사용자 흐름",
            img: require("@/assets/images/consulting/piece-24.svg")
          },
          {
            title: "Flow Chart",
            txt: "순서도/흐름도",
            img: require("@/assets/images/consulting/piece-25.svg")
          },
          {
            title: "A/B Testing",
            txt: "집단 비교 테스트",
            img: require("@/assets/images/consulting/piece-26.svg")
          }
        ],
        isModalOpen: false,
        swiperData: [
          {
            title: "동아출판",
            bgImg: require("@/assets/images/consulting/mo-1.png"),
            logoImg: require("@/assets/images/consulting/mo-1-logo.png"),
            title_1: "AI 디지털 교과서",
            title_2: "BX/UX 컨설팅&구축",
            sm_tit: "BXUX Consulting & UXUI Design",
            sm_txt:
              "인공지능과 디지털 기술의 발전을 반영해 서책 교과서의 한계를 넘어서는 AI 기반 디지털 교과서, 그리고 '모두를 위한 맞춤형 교육'을 목표로, 차별화된 브랜드 경험을 제공하기 위한 BX/UX 컨설팅 및 디자인을 진행하였습니다."
          },
          {
            title: "SK telecom",
            bgImg: require("@/assets/images/consulting/mo-2.png"),
            logoImg: require("@/assets/images/consulting/mo-2-logo.png"),
            title_1: "SK텔레콤 T다이렉트샵",
            title_2: "UX/UI 컨설팅",
            sm_tit: "UXUI Consulting",
            sm_txt:
              "SK텔레콤의 공식 온라인샵인 T다이렉트샵의 고객의 사용성 및 서비스 분석을 통해 현시대 사용성에 맞는 합리적 UX를 제공하고 T다이렉트 샵만의 차별화된 브랜드 경험 제공을 위한 개선 방향을 도출하였습니다."
          },
          {
            title: "신한은행",
            bgImg: require("@/assets/images/consulting/mo-3.png"),
            logoImg: require("@/assets/images/consulting/mo-3-logo.png"),
            title_1: "신한 O2O 서비스",
            title_2: "BX/UX 컨설팅",
            sm_tit: "BXUX Consulting",
            sm_txt:
              "신한은행에서 런칭하는 배달 서비스에 대한 여정 중심의 Task 수행/만족도 평가를 통해 사용성 이슈를 발굴하고, 신규 서비스 아이디어 발굴 및 BX/UX측면의 방향성을 제시하였습니다."
          },
          {
            title: "연합인포맥스",
            bgImg: require("@/assets/images/consulting/mo-4.png"),
            logoImg: require("@/assets/images/consulting/mo-4-logo.png"),
            title_1: "연합인포맥스 I-CAMS ",
            title_2: "BXUX Consulting",
            sm_tit: "Digital Transformation & Cloud Services",
            sm_txt:
              "자산운용사에서 사용하는 자산운용 솔루션 서비스에 대한 BX/UX 컨설팅 프로젝트 금융 서비스 중 가장 높은 난이도의 용어와 프로세스를 이해하고 사용자의 관점으로 서비스의 플로우와 UI를 고도화하였습니다. 자산운용 서비스의 경쟁없는 1위의 포지션에 비해 빈약한 브랜드 이미지와 경험을 고도화하여 고객의 긍정적 이미지를 확보하였습니다."
          },
          {
            title: "이베스트 투자증권 통합 MTS",
            bgImg: require("@/assets/images/consulting/mo-5.png"),
            logoImg: require("@/assets/images/consulting/mo-5-logo.png"),
            title_1: "이베스트 투자증권 통합 MTS",
            title_2: "UX/UI 컨설팅&구축",
            sm_tit: "UXUI Consulting & UXUI Design",
            sm_txt:
              "이베스트 온은 적응하는 MTS라는 목표 아래 주식거래 편의성을 극대화한 모바일 주식거래 경험을 제공합니다. 이베스트 온은 사용자에 따라 간편 모드 등 다양한 옵션을 선태할 수 있고, 중요한 정보를 즉각적으로 인지하여 쉽고 빠르게 모바일 상에서 주식거래를 할 수 있는 환경을 제공합니다."
          },
          {
            title: "현대백화점 그룹 통합 멤버십",
            bgImg: require("@/assets/images/consulting/mo-6.png"),
            logoImg: require("@/assets/images/consulting/mo-6-logo.png"),
            title_1: "현대백화점 그룹 통합 멤버십 <br/>H.Point APP",
            title_2: "UX/UI 컨설팅&구축",
            sm_tit: "UXUI Consulting & UXUI Design",
            sm_txt:
              "현대백화점 그룹 통합멤버십 H.Point은 새로운 포인트 소비 습관을 위해 통합 멤버십 서비스 H.Point를 전면 리뉴얼 개편하였습니다. 고객 편의성 강화를 위해 ‘고객인사이트 도출 컨설팅’을 선행하여 대고객 소통을 강화하는 APP을 설계하였습니다."
          },
          {
            title: "이터널저니 온라인 커머스 플랫폼",
            bgImg: require("@/assets/images/consulting/mo-7.png"),
            logoImg: require("@/assets/images/consulting/mo-7-logo.png"),
            title_1: "이터널저니 온라인 커머스 플랫폼",
            title_2: "BX/UX 컨설팅",
            sm_tit: "BXUX Consulting & UXUI Design",
            sm_txt:
              "아난티의 오프라인 샵인 이터널저니의 온라인 확장을 위해 컨설팅을 진행하며 현시점의 니즈를 분석하여 BX/UX의 방향성과 컨셉을 도출하였습니다. 도출된 컨셉의 시각화를 통해 아난티의 정체성을 담아내고, 반응형 웹사이트로 설계하여 다양한 디바이스에서 최적화된 서비스를 제공할 수 있도록 하였습니다."
          },
          {
            title: "푸마 공식 온라인몰",
            bgImg: require("@/assets/images/consulting/mo-8.png"),
            logoImg: require("@/assets/images/consulting/mo-8-logo.png"),
            title_1: "푸마 공식 온라인몰",
            title_2: "UX/UI 컨설팅",
            sm_tit: "UXUI Consulting",
            sm_txt:
              "푸마 공식 온라인몰의 UX/UI 품질 진단과 문제점 파악을 통해 UX, UI, Flow, BX측면의 사용성 개선 방안을 도출하였습니다."
          },
          {
            title: "NIKE",
            bgImg: require("@/assets/images/consulting/mo-9.png"),
            logoImg: require("@/assets/images/consulting/mo-9-logo.png"),
            title_1: "NIKE",
            title_2: "UX/UI 컨설팅",
            sm_tit: "UXUI Consulting",
            sm_txt:
              "NIKE.COM의 UX/UI 개선을 통한 브랜드 가치를 강화한 프로젝트로서 UX/UI 컨설팅을 통하여 온라인 쇼핑 경험 만족도 향상 및 매출 확대를 목표로 진행되었으며, 전략적 사용자 경험 분석을 통해 나이키만의 강점을 강화할 수 있는 체계적이고 구조적인 서비스 개선을 진행하였습니다."
          }
        ],
        swiperOptions: {
          loop: true,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
          }
        }
      };
    },
    computed: {},
    watch: {
      GE_CONT_HEIGHT() {
        if (this.$el.offsetParent) {
          this.init();
        }
      }
    },
    activated() {
      this.init();
    },
    mounted() {
      this.init();
      this.initSwiperPosition();
      window.addEventListener("scroll", this.handleScroll);
    },
    beforeDestroy() {
      window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
      init() {
        this.scene?.destroy(true);
        const keyVisual = document.querySelector(".key-visual");
        const tween = TweenMax.to(keyVisual.querySelector(".bg"), 1, {
          y: keyVisual.clientHeight,
          ease: Power0.easeInOut
        });
        const tween2 = TweenMax.to(keyVisual.querySelector(".txt"), 1, {
          y: keyVisual.clientHeight + keyVisual.querySelector(".txt").clientHeight,
          ease: Power0.easeInOut
        });
        this.scene = this.$scrollmagic
          .scene({
            triggerElement: keyVisual,
            triggerHook: 0,
            duration: keyVisual.clientHeight * 2
          })
          .setTween([tween, tween2]);
        this.$scrollmagic.addScene(this.scene);
      },
      initSwiperPosition() {
        const swiperSection = this.$refs.swiperSection;
        if (swiperSection) {
          TweenMax.set(swiperSection, {
            width: "100%",
            height: "100vh",
            position: "relative",
            margin: "10px auto 0",
            clipPath: "inset(0% 5% 85%)" // 상단 중간 부분만 보이도록 설정
          });
        }
      },
      handleScroll() {
        const scrollY = window.scrollY || window.pageYOffset;
        const swiperSection = this.$refs.swiperSection;
        // console.log("swiperSection", swiperSection);
        // console.log("scrollY", scrollY);
        if (swiperSection) {
          const sectionTop = swiperSection.offsetTop;
          const swiperHeight = window.innerHeight;
          const startScroll = sectionTop - swiperHeight * 0.7; // 스크롤 높이 30%에서 확장 시작
          const progress = Math.min(1, Math.max(0, (scrollY - startScroll) / (swiperHeight * 0.3))); // 30% 스크롤 내에서 확장 완료
          const insetBottom = 85 - 85 * progress; // 아래쪽 영역이 점점 확장되도록 변경
          const insetX = 5 - 5 * progress;
          TweenMax.to(swiperSection, 1, {
            clipPath: `inset(0% ${insetX}% ${insetBottom}% ${insetX}%)`,
            ease: Power2.easeOut,
            onUpdate: () => {
              // console.log("progress", progress);
              if (progress >= 0.25) {
                this.isExpanded = true;
                document.querySelectorAll(".swiper-text").forEach((text, index) => {
                  TweenMax.to(text, 1, {
                    opacity: 1,
                    y: 0,
                    delay: index * 0.1,
                    ease: Power2.easeOut
                  });
                });
              } else {
                this.isExpanded = false;
                document.querySelectorAll(".swiper-text").forEach(text => {
                  TweenMax.to(text, 0.5, {
                    opacity: 0,
                    y: 20,
                    ease: Power2.easeOut
                  });
                });
              }
            }
          });
        }
      },
      openModal() {
        this.isModalOpen = true;
        document.body.style.overflow = "hidden"; // body 스크롤 막기
      },
      closeModal() {
        this.isModalOpen = false;
        document.body.style.overflow = ""; // body 스크롤 원래대로 복구
      },
      toggle() {
        if (this.isOpen) {
          window.scrollTo({
            top: this.scrollPosition
          });
        } else {
          this.scrollPosition = window.scrollY;
        }
        this.isOpen = !this.isOpen;
      },
      toggle2() {
        if (this.isOpen2) {
          window.scrollTo({
            top: this.scrollPosition2
          });
        } else {
          this.scrollPosition2 = window.scrollY;
        }
        this.isOpen2 = !this.isOpen2;
      }
    },
    destroyed() {}
  };
</script>
<style lang="scss" scoped>
  .key-visual {
    position: relative;
    min-height: 779px;
    height: 100vh;
    min-width: $globalWidth;
    .mask {
      height: 100%;
      position: relative;
      overflow: hidden;
    }
    .contents {
      z-index: 2;
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      .txt {
        @include montserrat;
        margin-top: -30px;
        height: 369px;
        color: #fff;
        font-weight: 700;
        font-size: 30px;
        line-height: 43px;
        letter-spacing: -0.03em;
      }
    }
    .bg {
      position: absolute;
      top: 0;
      left: 0;
      min-width: $globalWidth;
      height: 100%;
      width: 100%;
      background: url(../../assets/images/consulting/img_consulting_main.webp) center center / cover
        no-repeat;
    }
  }
  .swiper-section {
    width: 100vw;
    height: 100vh;
    position: relative;
    margin: 10px auto 0;
    overflow: hidden;
    .swiper-button-next,
    .swiper-button-prev {
      position: absolute;
      top: 520px;
      z-index: 10;
      width: 36px;
      height: 36px;
      backdrop-filter: blur(200px);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      img {
        width: 18px;
        height: 18px;
      }
      &:after {
        display: none !important;
      }
    }
    .swiper-button-prev {
      left: 20px;
    }
    .swiper-button-next {
      left: 66px;
      transform: rotate(180deg);
    }
    .swiper-container {
      width: 100%;
      height: 100%;

      .swiper-slide {
        padding: 20.625vh 20px 0 20px;
        box-sizing: border-box;
        .inner {
          .logo {
            font-size: 0;
            text-indent: -9999px;
            width: 160px;
            height: 44px;
          }
          .title {
            margin-top: 46px;
            color: #fff;
            font-size: 18px;
            font-weight: 400;
            line-height: 28px;
            letter-spacing: -0.9px;
            strong {
              font-weight: 700;
              display: block;
            }
          }
          .sm-tit {
            margin-top: 30px;
            color: #fff;
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: -0.7px;
          }
          .sm-txt {
            margin-top: 10px;
            color: #fff;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.7px;
            word-break: keep-all;
          }
        }
      }
    }
    .swiper-text {
      opacity: 0;
    }
  }
  .lottie-cont {
    min-width: 320px;
    &.lottie-02 {
      margin-top: 20px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .section {
    padding: 0 20px;
    &.swiper {
      margin-top: 100px;
      padding: 0;
      .section-title {
        padding: 0 20px;
      }
    }
  }
  .section-1 {
    position: relative;
    margin-top: 70px;
  }
  .section-2 {
    margin-top: 120px;
    .puzzle-img {
      width: 55.56px;
      height: 20px;
    }
  }
  .section-3 {
    margin-top: 38px;
    .puzzle-img {
      width: 142px;
      height: 20px;
    }
    .board-list {
      margin-top: 20px;
      li {
        border-top: 1px solid #ddd;
        padding: 20px 0 40px;
        .board-img {
          display: block;
        }
        .tit {
          margin-top: 10px;
          color: #101010;
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;
          letter-spacing: -0.7px;
        }
        .txt {
          margin-top: 10px;
          color: #666;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: -0.42px;
          .tag {
            display: block;
            margin-top: 10px;
          }
        }
      }
    }
  }
  .section-4 {
    position: relative;
    margin-top: 120px;
  }
  .section-5 {
    margin-top: 120px;
  }
  .section-6 {
    margin-top: 59px;
  }
  .piece-wrap {
    position: relative;
    height: 354px;
    overflow: hidden;
    margin-bottom: 24px;
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 60px;
      z-index: 1;
      background: url(../../assets/images/consulting/blur.png) center center / cover no-repeat;
    }
    &.open {
      height: auto;
      margin-bottom: 40px;
      + .btn-open {
        bottom: -30px;
        &:after {
          transform: translateY(-50%) rotate(180deg);
        }
      }
      &:before {
        display: none;
      }
    }

    .piece-list {
      margin-top: 26px;
      li {
        + li {
          margin-top: 10px;
        }
        display: flex;
        justify-content: space-between;
        border: 1px solid #ddd;
        padding: 20px 0 0 20px;
        .piece-img {
          width: 50px;
          height: 55px;
        }
        .tit-wrap {
          .tit {
            color: #101010;
            font-family: Montserrat, "Noto Sans KR", sans-serif;
            font-size: 16px;
            font-weight: 700;
            line-height: 16px;
            letter-spacing: -0.8px;
          }
          .txt {
            margin-top: 10px;
            color: #101010;
            font-family: "Spoqa Han Sans Neo", sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.7px;
          }
        }
      }
    }
    .btn-wrap {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .btn-detail {
      margin-top: 15px;
      position: relative;
      color: #1e1e1e;
      font-family: "Noto Sans KR", sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.7px;
      border-radius: 30px;
      border: 1px solid #ddd;
      background: #fff;
      padding: 12px 43px 12px 20px;
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        width: 18px;
        height: 18px;
        background: url(../../assets/images/consulting/ic_plus.svg) no-repeat center/cover;
      }
    }
  }
  .section-title {
    color: #101010;
    font-family: "Noto Sans KR", sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -1px;
    .sub {
      display: block;
      color: #ee2c3c;
      font-family: "Noto Sans KR", sans-serif;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: -0.7px;
    }
    .puzzle {
      color: #ee2c3c;
    }
    .puzzle-img {
      margin-left: 5px;
      vertical-align: middle;
    }
  }
  .section-txt {
    margin-top: 10px;
    color: #101010;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.8px;
  }
  .btn-open {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -20px;
    color: #1e1e1e;
    font-family: "Noto Sans KR", sans-serif;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: -0.7px;
    z-index: 2;
    background: none;
    border: none;
    padding: 0;
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      right: -20px;
      transform: translateY(-50%);
      width: 18px;
      height: 18px;
      background: url(../../assets/icons/ic_down.svg) no-repeat center/cover;
    }
  }
  .cont-inner {
    height: 374px;
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    &.open {
      height: auto;
      padding-bottom: 15px;
      + .btn-open {
        &:after {
          transform: translateY(-50%) rotate(180deg);
        }
      }
      &:before {
        display: none;
      }
    }
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 60px;
      z-index: 1;
      background: url(../../assets/images/consulting/blur.png) center center / cover no-repeat;
    }
    .cont-list {
      li {
        + li {
          margin-top: 10px;
        }
        position: relative;
        padding: 20px;
        border: 1px solid #ddd;
        &:before {
          content: "";
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: $red;
          right: 20px;
          top: 20px;
        }
        .tit {
          font-weight: 400;
          color: #101010;
          font-family: Montserrat, "Noto Sans KR", sans-serif;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.7px;
          strong {
            display: block;
            font-weight: 700;
            margin-bottom: 5px;
          }
        }
        .txt {
          margin-top: 10px;
          color: #666;
          font-family: "Noto Sans KR", sans-serif;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: -0.7px;
        }
      }
    }
  }
  .process-list {
    margin-top: 30px;
    li {
      + li {
        margin-top: 54px;
      }
      .tit {
        color: #101010;
        font-family: "Noto Sans KR", sans-serif;
        line-height: 24px;
        font-weight: 700;
        .num {
          color: #ee2c3c;
          font-family: Montserrat, "Noto Sans KR", sans-serif;
          font-size: 16px;
          line-height: 16px;
        }
      }
      .img {
        margin-top: 20px;
      }
      .desc {
        margin-top: 20px;
        padding-top: 10px;
        border-top: 1px solid #ddd;
        .txt {
          color: #666;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: -0.7px;
          + .txt {
            margin-top: 10px;
          }
        }
      }
    }
  }
  .bg-img {
    margin: 22px 20px 0;
  }
  .modal-title span {
    opacity: 0.5;
    font-weight: 400;
  }
</style>
